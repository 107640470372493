@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Information {
      padding: 0 10px;
      padding-top: 100px;
      background-color: #ecf7fa;
      dl {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 10px;
        padding-bottom: 10px;
        dt {
          padding: 2px 0;
          background-color:#ff7a6d;
          border: #ff7a6d solid 1px;
          font-weight: bold;
          font-size: 16px;
          color: #fff;
        }
        dd {
          padding: 15px 10px;
          background-color: #fff;
          border: #ff7a6d solid 1px;
          text-align: left;
          p {
            overflow: hidden;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              display: inline-block;
              margin-right: 10px;
              padding: 4px 8px 4px 6px;
              background-color: #fbecdf;
              border-radius: 4px;
              font-family: 'M PLUS Rounded 1c', sans-serif;
              font-weight: 500;
              font-size: 12px;
              letter-spacing: 0.03em;
              color: #71a5db;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
    /* メインイメージ*/
    #MainImg {
      position: relative;
      height: 366px;
      padding: 0;
      #MainImgInner {
        position: absolute;
        top:0;
        left:0;
        right: 0;
        margin: auto;
        padding: 0;
        width: 100%;
        max-width: 1000px;
        text-align: center;
        z-index: 15;
        #CatchCopy {
          margin: 10px;
          padding: 5px;
          background-color: #fff;
          h2 {
            border: #fdebdb solid 2px;
            background-color: #fff;
            padding: 4px 0;
            color: #333;
            font-size: 14px;
            line-height: 1.4;
            border-radius: 0;
            width: 100%;
            span {
              font-size: 16px;
              color: #3383d7;
            }
          }
        }
      }
      ul.slider {
        position: relative;
        li {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 10;
          width: 100%;
          height: 366px;
          background-size: 1280px auto;
          background-position: center top;
          background-repeat: no-repeat;
          img {
            display: none;
          }
          &:nth-of-type(1){
          animation: anime 12000ms 0s infinite;
          background-image: url(../img/contents/top/mainimage01.jpg);
          background-color: #e5edf4;
          }

          &:nth-of-type(2){
          animation: anime02 12000ms 0s infinite;
          animation-delay: 4000ms;
          background-image: url(../img/contents/top/mainimage02.jpg);
          background-color: #f4e8db;
          }
          &:nth-of-type(3) {
          animation: anime03 12000ms 0s infinite;
          animation-delay: 8000ms;
          background-image: url(../img/contents/top/mainimage03.jpg);
          background-color: #eae7e4;
          }
        }
      }
        img.imageArea {
          opacity: 0;
        }
    }

@keyframes anime {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
         z-index:9;
    }
    100% { opacity: 0 }
}

@keyframes anime02 {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
         z-index:9;
    }
    100% { opacity: 0 }
}

@keyframes anime03 {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
         z-index:9;
    }
    100% { opacity: 0 }
}

  #Main {
    #ContBox01 {
      padding-top: 30px;
      h3 {
        position: relative;
        height: 18px;
        padding-left: 23px;
        margin-bottom: 6px;
        background: none;
        line-height: 18px;
        font-size: 14px;
        font-weight: bold;
        font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
        text-align: left;
        span {
          font-size: 11px;
          font-weight: normal;
        }
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 17px;
          height: 18px;
          background-image: url(../img/contents/top/icon_01.png);
          background-repeat: no-repeat;
          background-size: 17px auto;
          background-position: left center;
        }
      }
    }
    #ContBox02 {
      h3 {
        letter-spacing: -0.01em;
      }
      #ContSubBox01 {
        .linkRework {
          font-family: 'M PLUS Rounded 1c', sans-serif;
          font-weight: 500;
          margin-bottom: 22px;
          a {
            height: 90px;
            padding-top: 20px;
            padding-left: 95px;
            font-size: 18px;
            letter-spacing: -0.01em;
            span {
              font-size: 14px;
              letter-spacing: 0.3em;
            }
            &:before {
              position: absolute;
              content: "";
              top:0;
              bottom: 0;
              margin: auto;
              left: 10px;
              width: 75px;
              height: 75px;
              background-image: url(../img/contents/btnrework.png);
              background-size: 75px auto;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        .linkWelfare {
          font-family: 'M PLUS Rounded 1c', sans-serif;
          font-weight: 500;
          margin-bottom: 30px;
          a {
            height: 90px;
            padding-top: 6px;
            padding-left: 95px;
            font-size: 18px;
            letter-spacing: 0.1em;
            span {
              font-size: 14px;
              letter-spacing: 0.22em;
            }
            &:before {
              position: absolute;
              content: "";
              top:0;
              bottom: 0;
              margin: auto;
              left: 10px;
              width: 75px;
              height: 75px;
              background-image: url(../img/contents/btnwelfare.png);
              background-size: 75px auto;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }
      #ContSubBox02 {
        margin-top: 20px;
        padding: 20px 0 10px 0;
        background-color: #ecf7fa;
        ul.linkBnr {
          li {
            margin-bottom: 10px;
            &:nth-of-type(1) {
              a {
                background-image: url(../img/contents/top/btn_01.jpg);
              }
            }
            &:nth-of-type(2) {
              a {
                background-image: url(../img/contents/top/btn_02.jpg);
              }
            }
            &:nth-of-type(3) {
              a {
                background-image: url(../img/contents/top/btn_03.jpg);
              }
            }
          }
        }
      }
    }
    #ContBox03 {
      margin-top: -120px;
      padding-top: 120px;
      h3 {
        margin-top: -2px;
        margin-bottom: 10px;
        padding-top: 40px;
        height: 64px;
        background-image: url(../img/contents/top/icon_02.png);
        background-size: 54px auto;
        background-repeat: no-repeat;
        background-position: center top;
        line-height: 1;
      }
      #ContSubBox05 {
        .showBox {
          table {
            width: 100%;
            margin-bottom: 15px;
            td {
              padding: 4px 0 3px 10px;
              width: 50%;
              border: #fff solid 1px;
              font-weight: bold;
              letter-spacing: 0;
              color: #2a6aaf;
            }
          }
          dl {
            margin-top: 10px;
            margin-bottom: 0;
            dd {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    #ContBox04 {
      h3 {
        letter-spacing: -0.01em;
      }
    }
    #ContBox05 {
      h3 {
        letter-spacing: 0.07em;
      }
    }
    .contBox {
      ul.blogList {
        margin-top: -10px;
        margin-bottom: 15px;
        li {
          border-bottom: #e9e9e9 solid 1px;
          &:last-of-type {
            border: none;
          }
          a {
            display: block;
            padding: 10px 0 6px 0;
            text-decoration: none;
            span {
              display: inline-block;
              padding: 5px 9px 4px 10px;
              background-color: #fbecdf;
              border-radius: 4px;
              font-family: 'M PLUS Rounded 1c', sans-serif;
              font-weight: 500;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 1;
              color: #71a5db;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  }

  /*
  初めての方へ
  -------------------------------------*/
  #PageFirst.pageIndex {
    #MainImgInner {
      background-image: url(../img/contents/first/title.jpg);
    }
    #Main {
      #ContBox01 {
        #TelArea {
          margin-bottom: 20px;
          text-align: center;
          a {
            display: block;
            height: 63px;
            background-color: #2a6aaf;
            border-radius: 8px;
            font-size: 30px;
            font-weight: bold;
            color: #fff;
            text-decoration: none;
            letter-spacing: 0;
            line-height: 61px;

            }
            span {
              position: relative;
              display: inline-block;
              padding-left: 30px;
              line-height: 1.1;
              border-bottom: #fff solid 2px;
              &:before {
              position: absolute;
              content: "";
              top:1px;
              left: 0;
              width: 26px;
              height: 26px;
              background-image: url(../img/contents/first/icon_01.png);
              background-repeat: no-repeat;
              background-size: 26px;
              background-position: center top;
            }
          }
        }
        table {
          margin-bottom: 0;
          tr {
            th,td {
              font-size: 16px;
            }
            th {
              &:first-of-type {
                font-size: 14px;
              }
            }
            td {
              &:first-of-type {
                font-size: 14px;
              }
            }
          }
        }
      }
      #ContBox02 {
        margin-top: -100px;
        padding-top: 100px;
        h3 {
          margin-bottom: 20px;
        }
        #ContSubBox01 {
          .showBox {
            display: block;
          }
        }
        #ContSubBox04 {
          p {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*
  診療時間・アクセス
  -------------------------------------*/
  #PageConsult.pageIndex {
    #MainImgInner {
      background-image: url(../img/contents/consult/title.jpg);
    }
    #Main {
      #ContBox01 {
        h3 {
          height: 92px;
          padding-top: 20px;
          line-height: 1.3;
          margin-bottom: 20px;
          letter-spacing: 0.06em;
          span {
            display: block;
            font-size: 14px;
            letter-spacing: 0;
          }
        }
        dl {
          margin-bottom: 10px;
          dt{
            padding: 2px 0;
            background-color: #2a6aaf;
            border: #2a6aaf solid 1px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
          }
          dd {
            padding: 8px 0;
            border: #2a6aaf solid 1px;
            text-align: center;
            font-size: 16px;
            line-height: 1.4;
            span {
              font-size: 14px;
            }
          }
        }
        table.service {
          tr {
            th {
              width: 38%;
              font-size: 16px;
              &:first-of-type {
                width: 24%;
                font-size: 14px;
              }
            }
              td {
                padding: 9px 0 8px;
                width: 38%;
                font-size: 16px;
              }
          }
        }
        p.notes {
          margin-bottom: 0;
          margin-left: 14px;
          font-size: 14px;
          color: #6b6b6b;
          a {
            color: #5ab5e2;
          }
          &:before {
            content: "※";
            margin-left: -14px;
          }
        }
        #ContSubBox01 {
          margin-top: 15px;
          padding: 20px 0;
          background-color: #ecf7fa;
          .linkBnr {
            li {
              &:nth-of-type(1) {
                margin-bottom: 10px;
                a {
                  background-image: url(../img/contents/consult/btn_01.jpg);
                }
              }
              &:nth-of-type(2) {
                a {
                  background-image: url(../img/contents/consult/btn_02.jpg);
                }
              }
            }
          }
        }
      }
      #AccessBox {
        margin-top: -100px;
        padding-top: 100px;
      }
    }
  }

  /*
  医師の紹介
  -------------------------------------*/
  #PageDoctor.pageIndex {
    #MainImgInner {
      background-image: url(../img/contents/doctor/title.jpg);
    }
    .contBox {
      .innerBasic {
        text-align: center;
        padding-top: 15px;
        .photo {
          width: 193px;
          height: 193px;
          margin: 0 auto;
          border: 6.5px solid #ecf7fa;
          border-radius: 50%;
          img { width: 100%; }
        }
        .info {
          margin-top: 13px;
          ul {
            li.name {
              font-size: 18px;
              font-weight: 900;
              color: #2a6aaf;
              letter-spacing: 0.06em;
              small { font-size: 14px; }
            }
            li {
              font-size: 14px;
              color: #6b6b6b;
              letter-spacing: 0.02em;
            }
          }
          .capacity {
            max-width: 355px;
            height: 71px;
            margin: 0 auto;
            margin-top: 13px;
            dt {
              width: 30px;
              height: 100%;
              font-size: 16px;
              font-weight: 600;
              color: #fff;
              padding-top: 4px;
              background: #afd7ea;
              float: left;
              text-align: center;
              letter-spacing: 0.18em;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              -webkit-writing-mode: vertical-rl;
              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
            }
            dd {
              width: calc( 100% - 30px );
              height: 100%;
              font-size: 14px;
              background: #ecf7fa;
              float: left;
              padding: 12px 13px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              ul {
                text-align: left;
                li {
                  color: #333;
                  display: inline-block;
                  letter-spacing: -0.9px;
                }
              }
            }
          }
        }
        .message {
          width: 100%;
          margin-top: 20px;
          padding: 24px 15px 8px;
          background: #f9f9f9;
          border-radius: 10px;
          h4 {
            color: #2a6aaf;
            background: url(../img/contents/doctor/dotline.png) center left repeat-x;
            background-size: 114px 1px;
            text-align: center;
            margin-bottom: 12px;
            span {
              font-size: 18px;
              font-weight: 900;
              display: inline-block;
              margin-bottom: 0;
              background: #f9f9f9;
              padding: 0px 6px;
            }
          }
        }
        .txtImage {
          width: 100%;
          max-width: 355px;
          height: auto;
          margin-top: 19px;
          border-radius: 10px;
        }
      }
    }
    #ContBox01 {
      h3 {
        letter-spacing: 0.06em;
      }
      .info {
        .capacity {
          dd {
            ul li {
              &:nth-of-type(1) { margin-right: 50px; }
              &:nth-of-type(3) { margin-right: 12px; }
            }
          }
        }
      }
      p.linkBlank {
        margin: 20px 0 0 0;
        a {
          height: 88px;
          padding-top: 8px;
          padding-left: 20px;
          font-size: 24px;
          span {
            font-size: 18px;
            color: #d9f2fe;
          }
          &:after {
              right: 15px;
              height: 15px;
              width: 15px;
              background-image: url(../img/contents/lecture/icon_blank.png);
              background-size: 15px auto;
            }
        }
      }
      p.notes {
        margin: 10px 0 0 0 ;
        font-size: 14px;
        color: #6b6b6b;
        line-height: 1.4;
        letter-spacing: 0.02em;
      }
    }
    #ContBox02 {
      h3 {
        letter-spacing: 0.06em;
      }
      .innerBasic {
        padding-bottom: 0px;
        .info {
          .capacity {
            dd {
              ul li {
                &:nth-of-type(1) { margin-right: 12px; }
              }
            }
          }
        }
        .txtImage {
          margin-top: 21px;
        }
      }
    }
  }

  /*
  治療の特徴
  -------------------------------------*/
  #PageMedical-treatment.pageIndex {
    #MainImgInner {
      background-image: url(../img/contents/medical-treatment/title.jpg);
    }
    #ContBox01 {
      padding-top: 13px;
      padding-bottom: 23px;
      .innerBasic {
        font-size: 16px;
        dt {
          color: #2a6aaf;
          font-weight: bolder;
          letter-spacing: .07em;
        }
        p {
          padding-left: 16px;
          letter-spacing: .01em;
          margin-bottom: 10px;
        }
      }
    }
    #ContBox02 {
      padding-bottom: 16px;
      .innerBasic { margin-top: -5px; }
      h3 {
        letter-spacing: -0.01em;
      }
    }
    #ContBox03 {
      h3 {
        height: auto;
        padding: 20px 10px;
        line-height: 1.5;
        text-align: center;
        letter-spacing: -0.02em;
      }
      .innerBasic {
        margin-top: -7px;
        ul {
          margin-bottom: 15px;
          li {
            position: relative;
            padding-left: 15px;
            &:before {
              content: '';
              position: absolute;
              top: 9px;
              left: 0;
              width: 10px;
              height: 10px;
              background-color: #72a5dc;
              border-radius: 50%;
            }
          }
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  /*
  院内の紹介
  -------------------------------------*/
  #PageClinic.pageIndex {
    #MainImgInner {
      background-image: url(../img/contents/clinic/title.jpg);
    }
    #ContBox01 {
      padding-bottom: 0;
      ul {
        li {
          div {
            width: 100%;
            max-width: 375px;
            padding: 22px 10px 30px;
            margin: 0 auto;
          }
          p {
            width: 100%;
            text-align: center;
            background: url(../img/contents/clinic/dotline.png) repeat-x center left;
            background-size: 114px 1px;
            margin-bottom: 12px;
            b {
              font-size: 18px;
              color: #2a6aaf;
              font-weight: bolder;
              padding: 0px 10px;
              background: #fff;
            }
          }
          img {
            width: 100%;
            height: auto;
            border-radius: 10px;
          }
          &:nth-of-type(2n) {
            background: #ecf7fa;
            p b { background: #ecf7fa; }
          }
      .linkRework {
        margin-top: 20px;
        margin-bottom: 0;
      font-family: 'M PLUS Rounded 1c', sans-serif;
      font-weight: 500;
      a {
        height: 90px;
        padding-top: 17px;
        padding-left: 55px;
        font-size: 18px;
        span {
          font-size: 14px;
          letter-spacing: 0.3em;
        }
        &:before {
          position: absolute;
          content: "";
          top:0;
          bottom: 0;
          margin: auto;
          left: 10px;
          width: 75px;
          height: 75px;
          background-image: url(../img/contents/btnrework.png);
          background-size: 75px auto;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
        }
      }

    }
  }

  /*
  よくある質問
  -------------------------------------*/
  #PageFaq.pageIndex {
    #MainImgInner {
      background-image: url(../img/contents/faq/title.jpg);
    }
    #Main {
      .contBox {
        padding-top: 10px;
        h3 {
          margin-bottom: 0;
          padding: 4px 45px 8px 42px;
          background-image: none;
          height: auto;
          text-align: left;
          text-indent: -28px;
          line-height: 1.5;
          font-weight: bold;
          font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          span {
            font-size: 24px;
          }
        }
        .showBox {
          display: block;
          padding-left: 13px;
          p.anser {
            padding-left: 28px;
            font-size: 14px;
            text-indent: -26px;
            span {
              font-size: 24px;
              font-weight: bold;
              color: #2a6aaf;
              line-height: 1;
            }
          }
        }
        #ContSubBox01 {
          .linkBnr {
            margin-left: 25px;
            margin-bottom: 10px;
            a {
              background-image: url(../img/contents/faq/btn_01.jpg);
              span {
                letter-spacing: 0;
                padding: 0 10px;
              }
            }
          }
        }
        #ContSubBox02 {
          .linkBnr {
            margin-left: 25px;
            margin-bottom: 10px;
            a {
              background-image: url(../img/contents/faq/btn_02.jpg);
              span {
                padding: 0 10px;
                letter-spacing: 0.02em;
              }
            }
          }
        }
        #ContSubBox03 {
          .linkRework {
            margin-left: 25px;
            margin-bottom: 10px;
            a {
              height: 90px;
              padding-top: 20px;
              padding-left: 25px;
              font-size: 18px;
              letter-spacing: 0;
              span {
                font-size: 14px;
                letter-spacing: 0.3em;
              }
            }
          }
        }
      }
    }
  }

  /*
  復職リワーク滋賀
  -------------------------------------*/
  #PageRework.pageIndex {
    #MainImgInner {
      background-image: url(../img/contents/rework/title.jpg);
      h2 {
        padding: 0 12px 0 12px;
        font-size: 26px;
        height: 52px;
        line-height: 51px;
      }
    }
    #Main {
      #ContBox01 {
        padding-top: 13px;
        .circleArea {
          max-width: 355px;
          margin: 0 auto;
          margin-top: 23px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .circle {
            width: calc( (100% - 20px) / 3);
            height: 112px;
            border-radius: 50%;
            background: #2a6aaf;
            display: flex;
            text-align: center;
            align-items: center;
            p {
              display: block;
              margin: 0 auto;
              margin-bottom: 0;
              color: #fff;
              font-weight: bolder;
              text-align: center;
              line-height: 1.5;
              position: relative;
              top: 3px;
            }
          }
        }
      }
      #ContBox02 {
        .innerBasic {
          position: relative;
          margin-top: -6px;
          dl {
            margin-top: -4px;
            margin-bottom: 14px;
            dt {
              padding: 6px 15px;
              margin-bottom: 1px;
              background: #afd7ea;
              color: #fff;
              font-weight: bolder;
              line-height: 1.5em;
              text-align: justify;
            }
            dd {
              padding: 4px 15px;
              margin-bottom: 1px;
              background: #ecf7fa;
              font-size: 14px;
              line-height: 1.75;
              text-align: justify;
            }
          }
          ul {
            li {
              color: #6b6b6b;
              font-size: 14px;
              line-height: 1.75;
              position: relative;
              padding-left: 14px;
              &:before {
                content: '※';
                position: absolute;
                top: 1px;
                left: -2px;
              }
            }
          }
          img {
            max-width: 355px;
            width: 100%;
            border-radius: 10px;
            display: block;
            margin: 15px auto 0px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 320.99px) {
    #PageRework.pageIndex {
      #Main {
        #ContBox01 {
          .circleArea .circle {
            height: 93.33px;
            padding: 0px 2px;
            .xs-no { display: none; }
          }
        }
      }
    }
  }

  /*
  お知らせ
  -------------------------------------*/
  #PageTopics {
    #MainImgInner {
      background-image: url(../img/contents/topics/title.jpg);
    }
  }

  /*
  講演記録
  -------------------------------------*/
  #PageLecture {
    #MainImgInner {
      background-image: url(../img/contents/lecture/title.jpg);
    }
  }
  #PageLecture.pageIndex {
    #Main {
      #ContBox01 {
        padding-top: 20px;
        .linkBlank {
          a {
            height: 60px;
            font-size: 24px;
            line-height: 60px;
            padding-top: 0;
            padding-left: 20px;
            &:after {
              right: 15px;
              height: 15px;
              width: 15px;
              background-image: url(../img/contents/lecture/icon_blank.png);
              background-size: 15px auto;
            }
          }
        }
      p.notes {
        margin: 10px 0 40px 0 ;
        font-size: 14px;
        color: #6b6b6b;
        line-height: 1.4;
        letter-spacing: 0.04em;
      }
      }
    }
  }
  /*
  お知らせ・講演記録
  -------------------------------------*/
  #PageTopics,
  #PageLecture {
    #Main {
      .contBox {
        h3 {
          margin-bottom: 10px;
          height: auto;
          padding-bottom: 10px;
          text-align: left;
          background: none;
          border-bottom: #ecf7fa solid 3px;
          font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          span {
            display: inline-block;
            padding: 5px 9px 4px 10px;
            margin-bottom: 5px;
            background-color: #fbecdf;
            border-radius: 4px;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 1;
            color: #71a5db;
          }
          a {
            text-decoration: none;
            color: #2a6aaf;
          }
        }
        #BlogList {
          margin: 0 auto;
          margin-top: 15px;
          max-width: 1000px;
          li {
            display: none;
            padding: 0 10px;
            margin-bottom: 40px;
            border-bottom: #e9e9e9 solid 1px;
          }
        }
        .linkBtn {
          a {
            &:after {
              width: 15px;
              height: 15px;
              background-image: url(../img/contents/icon_more.png);
              background-size: 15px auto;
            }
          }
        }
        .readMoreBtn {
          display: none;
        }
      }
    }
  }

  #PageTopics.pageEntry,
  #PageLecture.pageEntry {
    #Main {
      #ContBox01 {
        padding-top: 20px;
        .entryBox {
          padding-bottom: 20px;
        }
        .linkBtn {
          margin-bottom: 15px;
          a {
            &:after {
              width: 15px;
              height: 15px;
              background-image: url(../img/contents/icon_top.png);
              background-size: 15px auto;
            }
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  /*
  404
  -------------------------------------*/
  #Page.page404 {
    #Container {
      padding-top: 100px;
      #Main {
        #ContBox01 {
          #SubContBox01 {
            margin: 50px 0;
            padding: 60px 0 50px;
            background-color: #fff5f6;
            border-radius: 8px;
            text-align: center;
            h2 {
              font-size: 35px;
              font-weight: bold;
              letter-spacing: -0.02em;
              color: #f69da7;
            }
            p {
              text-align: center;
            }
          }
          #SubContBox02 {
            padding-bottom: 75px;
            ul {
              width: 215px;
              margin: 0 auto;
              li {
                text-align: center;
                a {
                  font-size: 14px;
                  color: #5ab5e2;
                  text-decoration: underline;
                }
                &.linkBtn {
                  margin-bottom: 15px;
                  a {
                    position: relative;
                    display: block;
                    width: 215px;
                    height: 40px;
                    line-height: 40px;
                    color: #fff;
                    text-decoration: none;
                    text-align: center;
                    background-color: #6ac1ec;
                    border-radius: 20px;
                    font-family: 'M PLUS Rounded 1c', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    &:after {
                      position: absolute;
                      content: "";
                      top:0;
                      bottom: 0;
                      right: 15px;
                      margin: auto;
                      width: 15px;
                      height: 15px;
                      background-image: url(../img/contents/icon_top.png);
                      background-size: 15px auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  滋賀就労移行支援ひつじ
  -------------------------------------*/
  #PageWelfare {
    #MainImg {
      background: linear-gradient(to right, #e9f6ff, #c8e7fc);
      #MainImgInner {
        height: 230px;
        padding-top: 68.5px;
        background-image: url(../img/contents/welfare/mainimg.png);
        background-size: 1280px auto;
        background-position: center top;
        h2 {
          width: 100%;
          max-width: 375px;
          padding: 0 0 0 6px;
          margin: 0 auto;
          border: 0;
          p {
            width: 200px;
            height: 145px;
            background: url(../img/contents/welfare/catchWord.png) no-repeat left top;
            background-size: 100% auto;
            text-indent: -9999px;
            margin-bottom: 0;
          }
        }
      }
    }
    #Main {
      .form {
        table {
          display: block;
          tbody, tr, th, td {
            display: block;
            width: 100%;
          }
          tr {
            &:nth-last-of-type(2) {
              input {
                width: 59px;
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
          th {
            padding-left: 45px;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 9px;
            position: relative;
            &:before {
              position: absolute;
              content: '必須';
              width: 35px;
              background-color: #f69da8;
              font-size: 12px;
              color: #fff5f6;
              line-height: 1;
              text-align: center;
              padding: 4px 0;
              border-radius: 4px;
              top: -2px;
              left: 0;
            }
          }
          td { margin-bottom: 20px; }
        }
        input {
          display: block;
          width: 100%;
          background-color: #f9f9f9;
          font-size: 16px;
          line-height: 2;
          padding: 11px 15px;
          border-radius: 8px;
        }
        input[type="number"] {
            -moz-appearance:textfield !important;
        }
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }
        textarea {
          width: 100%;
          height: 169px;
          background-color: #f9f9f9;
          font-size: 16px;
          line-height: 2;
          padding: 11px 15px;
          resize: vertical;
        }
        button, a {
          display: block;
          width: 215px;
          height: 40px;
          background: #6ac1ec url(../img/contents/arrow_03.png) no-repeat right 15px center;
          background-size: 7px auto;
          font-family: "M PLUS Rounded 1c", sans-serif;
          font-size: 16px;
          color: #fff;
          border-radius: 20px;
          margin: 0 auto;
        }
      }
    }
  }
  #PageWelfare.pageIndex {
    #Main {
      p {
        line-height: 29px;
          &.highlight {
          margin-bottom: 0;
          strong {
            font-size: 16px;
            font-weight: bold;
            color: #2a6aaf;
            letter-spacing: 0.05em;
            line-height: 29px;
          }
        }
      }
      ul {
        li {
          position: relative;
          padding-left: 15px;
          letter-spacing: 0.05em;
          line-height: 29px;
          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 0;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background-color: #71a5db;
          }
        }
      }
      a.button {
        display: block;
        width: 215px;
        height: 40px;
        background: #6ac1ec url(../img/contents/arrow_03.png) no-repeat right 15px center;
        background-size: 7px auto;
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-size: 16px;
        color: #fff;
        letter-spacing: 0.1em;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        border-radius: 20px;
        margin: 23px auto 0;
      }
      .contBox {
        h3 { margin-bottom: 30px; }
      }

      .enterpriseBtn {
        position: fixed;
        width: 100%;
        background: none;
        bottom: 0;
        left: 0;
        padding: 25px 0;
        z-index: 30;
        text-align: center;
        a {
          display: block;
          width: calc(100% - 50px);
          background: #2a6aaf url(../img/contents/arrow_03.png) no-repeat right 20px center;
          background-size: 7px auto;
          font-family: "M PLUS Rounded 1c", sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          line-height: 24px;
          text-decoration: none;
          padding: 18px 30px;
          margin: 0 auto;
          border-radius: 60px;
        }
      }
      #ContBox01 {
        h3 { margin-bottom: 27px; }
        h4 {
          font-family: "M PLUS Rounded 1c", sans-serif;
          font-size: 16px;
          letter-spacing: 0.05em;
          line-height: 20px;
          text-align: center;
          span { color: #3383d7; }
          & + h4 { margin-top: 10px; }
        }
        .text {
          background-color: #ecf7fa;
          padding: 11px 15px 12px;
          margin-top: 28px;
          margin-bottom: 22px;
          p { margin-bottom: 0; }
        }
      }
      #ContBox02 {
        padding-bottom: 50px;
        .box {
          width: 100%;
          height: 70px;
          background-color: #afd7ea;
          position: relative;
          p {
            font-weight: bold;
            line-height: 70px;
            text-align: center;
            margin-bottom: 0;
          }
          &:after {
            position: absolute;
            content: '';
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #afd7ea;
            left: 50%;
            bottom: -12px;
            transform: translateX(-50%);
            z-index: 1;
          }
          &:nth-of-type(even) {
            background-color: #ecf7fa;
            &:after { border-top-color: #ecf7fa; }
          }
          &:last-of-type:after { display: none; }
        }
      }
      #ContBox03 {
        h3 { margin-bottom: 29px; }
        .showBox {
          padding-bottom: 30px;
          .itemBox {
            & + .itemBox { margin-top: 15px; }
            dl {
              dt {
                background-color: #afd7ea;
                font-weight: bold;
                color: #fff;
                letter-spacing: 0.05em;
                line-height: 24px;
                padding: 10px 15px 11px;
                &:before { display: none; }
              }
              dd {
                background-color: #fff;
                font-size: 14px;
                letter-spacing: 0.05em;
                line-height: 24px;
                text-align: justify;
                padding: 9px 15px 10px;
                margin: 0;
              }
            }
            ul {
              li { margin-bottom: 0; }
            }
            &:nth-of-type(2) {
              .highlight { margin-bottom: 7px; }
            }
          }
        }
      }
      #ContBox04 {
        padding-bottom: 23px;
        h3 {
          height: 100%;
          line-height: 29px;
          padding: 20px 0 16px;
          margin-bottom: 22px;
          small {
            display: block;
            font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
            font-size: 14px;
            letter-spacing: -0.025em;
            margin-top: -3px;
          }
        }
        .text + .text { margin-top: 15px; }
      }
      #ContBox05 {
        table {
          width: 100%;
          margin-bottom: 30px;
          th, td {
            font-size: 14px;
            letter-spacing: 0.05em;
            line-height: 24px;
            padding: 10px 10px 12px;
            border-bottom: 1px solid #fff;
          }
          th {
            width: 25%;
            font-weight: 500;
            letter-spacing: 0.1em;
            vertical-align: middle;
            background-color: #ecf7fa;
          }
          td {
            width: 75%;
            background-color: #f9f9f9;
            border-left: 1px solid #fff;
          }
        }
        p.click {
          font-size: 14px;
          color: #5ab5e2;
          letter-spacing: 0.1em;
          line-height: 16px;
          text-align: center;
          margin-top: 39px;
          margin-bottom: 0;
          span {
            display: inline-block;
            text-decoration: underline;
            &:after {
              display: inline-block;
              content: '';
              width: 13px;
              height: 13px;
              background: url(../img/contents/arrow_open_02.png) no-repeat left top;
              background-size: 100% auto;
              margin-left: 2px;
              transform: translateY(1px);
            }
          }
          &.active {
            span:after { background-image: url(../img/contents/arrow_close_02.png); }
          }
        }
        .showBox {
          background-color: transparent;
          padding: 18px 0 0;
          ul {
            li { text-align: justify; }
          }
          table {
            margin-top: 23px;
            margin-bottom: 0;
            tr {
              &:first-of-type {
                th, td {
                  background-color: #9ccae0;
                  font-weight: bold;
                  color: #fff;
                  letter-spacing: 0.1em;
                  text-align: center;
                }
                td { line-height: 18px; }
              }
            }
            th {
              width: 71%;
            }
            td {
              width: 29%;
            }
          }
        }
      }
    }
  }

  /*
  施障害者雇用率が未達成の企業様へ
  -------------------------------------*/
  #PageWelfare.pageEmployment {
    #Main {
      #ContBox01 {
        padding-bottom: 43px;
        h3 {
          height: auto;
          line-height: 31px;
          padding: 19px 0 20px;
        }
        .innerBasic { margin-top: 20px; }
        p {
          letter-spacing: 0;
          line-height: 29px;
          margin-bottom: 0;
          span { color: #3383d7; }
          small { font-size: 12px; }
          strong {
            font-size: 18px;
            font-weight: 500;
            color: #3383d7;
            line-height: 32px;
          }
        }
        .colorBox {
          &.blue {
            background-color: #ecf7fa;
            padding: 13px 15px 11px;
          }
          &.blue01 {
            margin-top: 18px;
            p {
              font-weight: bold;
              color: #2a6aaf;
            }
            ul {
              li {
                color: #3383d7;
                line-height: 29px;
                position: relative;
                padding-left: 15px;
                &:before {
                  position: absolute;
                  content: '';
                  width: 10px;
                  height: 10px;
                  background-color: #71a5db;
                  border-radius: 50%;
                  top: 9px;
                  left: 0;
                }
              }
            }
          }
          &.blue02 {
            margin-top: 40px;
            margin-bottom: 32px;
            p {
              font-size: 18px;
              color: #3383d7;
              line-height: 32px;
            }
          }
          &.gray {
            background-color: #f9f9f9;
            padding: 13px 15px 12px;
            margin-top: 20px;
            h4 {
              font-family: "M PLUS Rounded 1c", sans-serif;
              font-size: 24px;
              font-weight: bold;
              line-height: 36px;
              text-align: center;
              margin-bottom: 5px;
            }
            h5 {
              font-size: 18px;
              font-weight: bold;
              line-height: 32px;
              margin-top: 6px;
              span { color: #3383d7; }
              small {
                font-size: 12px;
                font-weight: 500;
                line-height: 2;
              }
            }
            p {
              & + p { margin-top: 8px;}
            }
          }
        }
        .contactBox {
          margin-top: 35px;
          margin-bottom: 50px;
          p {
            font-size: 20px;
            font-weight: bold;
            color: #2a6aaf;
            text-align: center;
            margin-bottom: 12px;
          }
          a {
            display: block;
            width: 100%;
            max-width: 325px;
            height: 50px;
            background: #2a6aaf url(../img/contents/arrow_03.png) no-repeat right 20px center;
            background-size: 7px auto;
            font-family: "M PLUS Rounded 1c", sans-serif;
            color: #fff;
            line-height: 50px;
            text-align: center;
            text-decoration: none;
            border-radius: 25px;
            margin: 0 auto;
          }
        }
      }
      #ContBox02 {
        h3 { margin-bottom: 25px; }
        .form {
          table {
            tr {
              &:nth-last-of-type(2) {
                input {
                  width: 59px;
                  display: inline-block;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  施障害者雇用率が未達成の企業様へ 確認画面
  -------------------------------------*/
  #PageWelfare.pageConfirm {
    #Main {
      #ContBox01 {
        h3 {
          height: auto;
          line-height: 31px;
          padding: 19px 0 20px;
        }
        p {
          line-height: 29px;
          text-align: center;
          margin: 21px 0 32px;
          &.error_messe { margin: 0; }
          &.font-rounded { font-family: "M PLUS Rounded 1c", sans-serif; }
        }
        a {
          display: block;
          width: 215px;
          height: 40px;
          background: #f9f9f9 url(../img/contents/arrow_04.png) no-repeat left 15px center;
          background-size: 7px auto;
          font-family: "M PLUS Rounded 1c", sans-serif;
          font-size: 16px;
          color: #333;
          line-height: 40px;
          text-align: center;
          text-decoration: none;
          border-radius: 20px;
          margin: 15px auto 0;
        }
        .form {
          table {
            margin-bottom: 32px;
            th { margin-bottom: 6px; }
            td {
              line-height: 29px;
              margin-bottom: 17px;
            }
          }
          a {
            background-color: #f9f9f9;
            background-image: url(../img/contents/arrow_04.png);
            background-position: left 15px center;
            color: #333333;
            line-height: 40px;
            text-align: center;
            text-decoration: none;
            margin-top: 15px;
          }
        }
      }
    }
  }

  /*
  施障害者雇用率が未達成の企業様へ 完了画面
  -------------------------------------*/
  #PageWelfare.pageThanks {
    #Main {
      #ContBox01 {
        padding-bottom: 33px;
        h3 {
          height: auto;
          line-height: 31px;
          padding: 19px 0 20px;
        }
        p {
          line-height: 29px;
          text-align: center;
          margin: 21px 0 32px;
          &:first-of-type { font-family: "M PLUS Rounded 1c", sans-serif; }
        }
        h4 {
          font-family: "M PLUS Rounded 1c", sans-serif;
          font-size: 18px;
          line-height: 29px;
          text-align: center;
          & + p { margin: 0; }
        }
      }
    }
  }


  /*
  ブラウザ幅別設定
  -------------------------------------*/
@media print, screen and (min-width: 1001px)  {
  #Page.pageIndex #MainImg #MainImgInner #CatchCopy {
    margin: 10px 0;
  }
  #PageTopics,
  #PageLecture {
    #Main {
      .contBox {
        #BlogList {
          li {
            padding: 0;
          }
        }
      }
    }
  }

  #PageWelfare.pageIndex {
    #Main {
      .enterpriseBtn {
        position: relative;
        background: linear-gradient(to right, #e9f6ff, #c8e7fc);
        padding: 20px 0;
        z-index: auto;
        a {
          width: 304px;
          height: 50px;
          background-position: right 19px center;
          background-size: 8px auto;
          letter-spacing: 0;
          line-height: 50px;
          padding: 0;
        }
      }
    }
  }
}

@media print,
screen and (max-width: 320px) {
  #Header {
    h1 {
      width: 140px;
      a {
        width: 140px;
        background-size: 140px auto;
      }
    }
    #CallBoxHeader {
      p {
        font-size: 14px;
      }
    }
  }

  #Page.pageIndex #MainImg #MainImgInner img {
    width: 300px;
    height: auto;
    margin: 20px 0 25px 0;
  }
  #Page.pageIndex #MainImg #MainImgInner #CatchCopy h2 {
    font-size: 12px;
  }
  #Page.pageIndex #MainImg #MainImgInner #CatchCopy h2 span {
    font-size: 15px;
  }
  #PageDoctor.pageIndex .contBox .innerBasic .txtImage {
    width: 100%;
  }
  #PageDoctor.pageIndex .contBox .innerBasic .info .capacity dd ul li {
    font-size: 11px;
  }
  #Page.pageIndex #Main #ContBox02 #ContSubBox01 .linkRework a {
    padding-left: 90px;
    font-size: 16px;
  }
  #PageClinic.pageIndex #ContBox01 ul li .linkRework a {
    padding-left: 60px;
    font-size: 16px;
  }
  #Page.pageIndex #Main #ContBox02 #ContSubBox01 .linkRework a span,
  #PageClinic.pageIndex #ContBox01 ul li .linkRework a span {
    font-size: 12px;
  }
  #PageFaq.pageIndex #Main .contBox #ContSubBox02 .linkBnr a span {
    font-size: 14px;
  }
  #PageFaq.pageIndex #Main .contBox #ContSubBox03 .linkRework a {
    padding-left: 10px;
  }
  #PageWelfare.pageIndex #Main .contBox h3 {
    padding: 20px 0 16px;
    height: 100%;
    line-height: 29px;
  }
  #Footer .innerBasic ul li {
    width: calc((100% - 22px) / 2);
  }
  #Footer .innerBasic ul li:nth-child(odd) {
    margin-right: 20px;
  }
}

@media print,
screen and (max-width: 360px) {
  #PageDoctor.pageIndex .contBox .innerBasic .info .capacity dd ul li {
    font-size: 12px;
  }

  #PageWelfare.pageIndex #Main #ContBox01 h3 {
    margin-bottom: 22px;
    padding: 20px 0 16px;
    height: 100%;
    line-height: 29px;
  }
}


@media print,
screen and (min-width: 768px) {

}
@media print,
screen and (max-width: 767px) {

}